import { FC, useState } from 'react'
import styles from './ToOrder.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { Button, Modal } from 'components/atom'
import InputMask from 'react-input-mask'

import { CloseIcon, PaintIcon } from 'assets'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { createOrder } from 'store/features/Order/orderSlice'
import { last } from 'lodash'

const ToOrder: FC<any> = ({ data, onOrderButtonClick, disable }) => {
  const location: any = useLocation()
  const isPlacing = location.pathname === '/placing'

  const { register, handleSubmit, formState, setValue } = useForm()
  const [formData, setFormData] = useState<any>({})
  const [telephone, setTelephone] = useState('')
  const dispatch = useAppDispatch()
  const { basketSlice } = useAppSelector(state => state)

  const productsBasketData = basketSlice.data ? basketSlice.data.data : []

  const countries: any = useAppSelector(state => state.orderSlice.countries)

  const [showModal, setShowModal] = useState(false)
  const [showResErrors, setShowResErrors] = useState(false)
  const loading: boolean = useAppSelector(state => state.orderSlice.loading)

  const [orderRes, setOrderRes] = useState<any>([])
  const [orderId, setOrderId] = useState<any>(null)

  const [status, setStatus] = useState<any>([])

  const Loading = () => (
    <div className='full__loader'>
      <span className='loader'></span>
    </div>
  )

  const [showOrder, setShowOrder] = useState(false)
  const handleClose = () => {
    setShowOrder(false)
  }

  const handleOrderButtonClick = async (form: any) => {
    try {
      const orderData = {
        check: false,
        city_id: 2,
        payment_method: 'cod',
        shipping_street: '',
        firstname: form.firstname,
        lastname: 'ANY',
        telephone: telephone,
        store_id: process.env.REACT_APP_STORE_ID,
      }

      const response = await dispatch(createOrder(orderData))

      const data: any = response.payload

      if (data.status === 1) {
        setOrderRes(data.payment)
        setOrderId(data?.order_id)
        setStatus(data.status)
        setShowModal(true)
      }
      if (data.status === 2) {
        window.location.href = data.url
      }
      if (data.status === 3) {
        setOrderRes(data.payment)
        setStatus(data.status)
        setShowResErrors(true)
      }
      if (data.warning) {
        setOrderRes(data.warning)

        setShowModal(true)
      }
      setShowModal(true)
    } catch (error) {
      setShowModal(true)
      setOrderRes("Переконайтеся, що всі обов'язкові поля заповнені")
    }
  }

  const handleFormSubmit = (data: any) => {
    console.log('caal', data)
    handleOrderButtonClick(data)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__prices}>
        <div>
          <p>Вартість замовлення</p>
          <span>{data?.total_format}</span>
        </div>
        <div className={styles.wrapper__prices__footer}>
          <p>До оплати без доставки</p>
          <span>{data?.total_format}</span>
        </div>
      </div>
      <div className={styles.wrapper__buttons}>
        {isPlacing ? (
          <div style={{ opacity: disable ? 1 : 0.5, cursor: disable ? 'not-allowed' : 'auto' }}>
            {' '}
            <Button onClick={onOrderButtonClick} className={styles.wrapper__buttons__btn_1}>
              Замовити
            </Button>
          </div>
        ) : (
          <>
            {' '}
            <Button onClick={() => setShowOrder(true)} className={styles.wrapper__buttons__btn_2}>
              Замовити в один клік
            </Button>
          </>
        )}
      </div>

      {/* {showOrder && ( */}
      <Modal show={showOrder} disableClose={true} onClose={handleClose} icon={PaintIcon}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className=' flex justify-end w-full'>
            <CloseIcon className='cursor-pointer' onClick={handleClose} />
          </div>
          <div className={styles.modal__login__confirm}>
            <p className=''>
              Для того, щоб здійснити замовлення в один клік, будь ласка, введіть ваш <b> номер телефону </b>{' '}
            </p>
            <div className={styles.wrapper__input_block}>
              <label>Ім’я отримувача *</label>
              <input
                type='text'
                placeholder='Андрій'
                {...register('firstname', { required: 'Поле є обов’язковим' })}
                onChange={e => setValue('firstname', e.target.value)}
              />
              {formState.errors?.firstname && (
                <p style={{ marginBottom: '0px', marginTop: '10px', color: '#FFA800', textAlign: 'start' }}>
                  {formState.errors.firstname.message?.toString()}
                </p>
              )}
            </div>
            <div className={styles.wrapper__input_block}>
              <label>Телефон *</label>

              <InputMask
                mask='+38 (099) 999-99-99'
                maskChar='_'
                type='tel'
                value={telephone}
                placeholder='+38 (0__) ___-__-__'
                {...register('number', { required: 'Поле є обов’язковим' })}
                onChange={e => {
                  const cleanedValue = e.target.value.replace(/\D/g, '') // Remove non-digit characters
                  setValue('number', cleanedValue)
                  setTelephone(cleanedValue)
                }}
              />
              {formState.errors?.number && (
                <p style={{ marginBottom: '0px', marginTop: '10px', color: '#FFA800', textAlign: 'start' }}>
                  {formState.errors?.number.message?.toString()}
                </p>
              )}
            </div>{' '}
            <button type='submit' className={styles.modal__login__confirm__buttons}>
              <Button className={styles.modal__login__confirm__buttons__btn1}>Замовити</Button>
            </button>
          </div>
        </form>
      </Modal>
      {/* )} */}

      <Modal disableClose={true} show={showModal} onClose={() => setShowModal(false)}>
        {orderId && <h1 className=' text-center mb-4'> Hомер вашого замовлення №{orderId}</h1>}
        <h1
          className={
            status === 1 ? ' text-center max-w-sm  text-[#4fbf26] my-3' : ' max-w-sm text-red-700 my-3 text-center'
          }
        >
          {orderRes}
        </h1>
        {/* {status ===   1 && <h1 className={styles.wrapper__modalText}>ТТН: {ttnRest}</h1>} */}
        <a
          style={{ marginTop: '10px', width: '80%', margin: '10px auto' }}
          href={status === 1 ? '/categories' : '/basket'}
        >
          <Button> {status === 1 ? 'Каталог' : 'Закрити'} </Button>
        </a>
      </Modal>

      {showResErrors && (
        <Modal onClose={() => setShowResErrors(false)}>
          <h1 className='text-center text-red-700 my-3'>{orderRes}</h1>
          <div style={{ marginTop: '10px', width: '80%', margin: '10px auto' }}>
            <Button onClick={() => setShowResErrors(false)} className=' bg-red-600 '>
              Закрити
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ToOrder
